import React, { useEffect } from 'react';
import '../styles/styles.scss'
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Logo from '../images/cyclone.inline.svg'

const Release = ({data}) => {
  const releaseData = data.releasesYaml
  const image = getImage(releaseData.cover)

  useEffect(() => {
      colorChange()
  })

  const colorChange = () => {
    if (releaseData.colors) {
      document.documentElement.style.setProperty('--headerColor', releaseData.colors.header)
      document.documentElement.style.setProperty('--textColor', releaseData.colors.text)
      document.documentElement.style.setProperty('--accentColor', releaseData.colors.accent)
      document.documentElement.style.setProperty('--barColor', releaseData.colors.bar)
      document.documentElement.style.setProperty('--backgroundColor', releaseData.colors.background)
    } else {
      document.documentElement.style.setProperty('--headerColor', 'rgb(50,50,50)')
      document.documentElement.style.setProperty('--textColor', 'rgb(20,20,20)')
      document.documentElement.style.setProperty('--accentColor', 'rgb(80,80,80)')
      document.documentElement.style.setProperty('--barColor', 'rgba(200,200,200,.35)')
      document.documentElement.style.setProperty('--backgroundColor', 'rgb(255,255,255)')
    }
  }

  return (
    <main className="main releaseLayout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cyclone {releaseData.releaseNum}</title>
        <link rel="stylesheet" href="https://use.typekit.net/vso5rvi.css" />
      </Helmet>
      <header className="releaseHeader">
        {/* <Logo className="logo" /> */}
        {/* <h1>{releaseData.releaseNum}</h1> */}
        <GatsbyImage className="releaseImage" image={image} alt={`Cyclone ${releaseData.releaseNum}`} />
      </header>
      
      <div className="trackDataContainer">
        <ol className="trackList">
        {releaseData.tracks.map((track, index) => (
          <li id={index+1} className="trackItem" key={track.title}>
                <TrackHeader data={track} />
              <div className="choreoList">
                {track.choreo?.map((block, index) => (
                  <ul className="choreoBlock" key={index}>
                    <h4 className="blockHeader">{`Block ${index + 1}`}</h4>
                    {block.map(step => (
                      <Step step={step} key={step.time} />
                    ))}
                  </ul>
                ))}
              </div>
          </li>
        ))}
        </ol>
      </div>
    </main>
  )
}

/* Components */
// Track header 
const TrackHeader = ({data}) => {
  return <header className="trackHeader">
    <Triangle />
    <h2 className="title">{data.title}</h2> 
    <h3 className="artist">{data.artist}</h3>
    <h3 className="duration">
      <MinSec seconds={data.duration} />
    </h3>
    <h4 className='bpm'>{data.bpm}<span>bpm</span></h4>
    <h4 className='profile'>{data.profile}</h4>
  </header>
}

// Row layout
const Step = ({step}) => {
  return <li className="choreoStep">
    <p className="choreoTime">{step.time}</p>
    <p className="choreoMusic">{step.music}</p>
    <p className="choreoBeats">{step.beats}
      <span>x8</span>
    </p>
    <p className="choreoAction">{step.action}</p>
    <p className="choreoGear">
      <Iconify gear={step.gear} />
    </p>
    <p className="choreoSpeed">{step.speed}</p>
    <p className="choreoEffort">
      <Effortize effort={step.effort} />
    </p>
  </li>
}

// Converts seconds to min:sec
const MinSec = ({seconds}) => {
  let duration = new Date(null);
  duration.setSeconds(seconds);
  return duration.toISOString().substring(15, 19);
}

// Converts gear to icon 
const Iconify = ({gear}) => {
  switch (gear) {
    case '+':
      return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><polyline points="2 2 14 2 14 14"/></svg>
    case '-':
      return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><polyline points="14 14 2 14 2 2"/></svg>
  
    default:
      return gear
  }
}

// Returns square icons for effort
const Effortize = ({effort}) => {
  switch (effort) {
    case 'L':
      return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 12"><rect width="12" height="12"/></svg>
    case 'M': 
      return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 12"><rect width="12" height="12"/><rect x="14" width="12" height="12"/></svg>
    case 'H': 
      return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 12"><rect width="12" height="12"/><rect x="14" width="12" height="12"/><rect x="28" width="12" height="12"/></svg>
    default:
      return ''
  }
}

// Triangle track header icon
const Triangle = () => {
  return <div className='triangle'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
      <polygon points="1.61 1.61 0 2.37 0 3.47 2.37 2.37 1.61 1.61"/>
      <polygon points="0 7.45 5.07 5.07 4.32 4.32 0 6.35 0 7.45"/>
      <polygon points="0 11.43 7.78 7.78 7.03 7.03 0 10.32 0 11.43"/>
      <polygon points="0 15.4 10.49 10.49 9.74 9.74 0 14.3 0 15.4"/>
      <polygon points="0 19.38 13.2 13.2 12.44 12.44 0 18.28 0 19.38"/>
      <polygon points="0 23.36 15.9 15.9 15.15 15.15 0 22.25 0 23.36"/>
      <polygon points="0 27.33 18.61 18.61 17.86 17.86 0 26.23 0 27.33"/>
      <polygon points="0 31.31 21.32 21.32 20.57 20.57 0 30.2 0 31.31"/>
      <polygon points="0 35.29 24.03 24.03 23.28 23.28 0 34.18 0 35.29"/>
      <polygon points="0 39.26 26.73 26.73 25.98 25.98 0 38.16 0 39.26"/>
      <polygon points="0 43.24 29.44 29.44 28.69 28.69 0 42.13 0 43.24"/>
      <polygon points="0 47.21 32.15 32.15 31.4 31.4 0 46.11 0 47.21"/>
      <polygon points="0 51.19 34.86 34.86 34.11 34.11 0 50.09 0 51.19"/>
      <polygon points="0 55.17 37.56 37.56 36.81 36.81 0 54.06 0 55.17"/>
      <polygon points="0 59.14 40.27 40.27 39.52 39.52 0 58.04 0 59.14"/>
      <polygon points="0 63.12 42.98 42.98 42.23 42.23 0 62.02 0 63.12"/>
      <polygon points="0 67.1 45.69 45.69 44.94 44.94 0 65.99 0 67.1"/>
      <polygon points="0 71.07 48.4 48.4 47.64 47.64 0 69.97 0 71.07"/>
      <polygon points="0 75.05 51.1 51.1 50.35 50.35 0 73.94 0 75.05"/>
      <polygon points="0 79.03 53.81 53.81 53.06 53.06 0 77.92 0 79.03"/>
      <polygon points="6.41 80 56.52 56.52 55.77 55.77 4.05 80 6.41 80"/>
      <polygon points="14.89 80 59.23 59.23 58.47 58.47 12.53 80 14.89 80"/>
      <polygon points="23.38 80 61.93 61.93 61.18 61.18 21.02 80 23.38 80"/>
      <polygon points="31.86 80 64.64 64.64 63.89 63.89 29.51 80 31.86 80"/>
      <polygon points="40.35 80 67.35 67.35 66.6 66.6 37.99 80 40.35 80"/>
      <polygon points="48.83 80 70.06 70.06 69.3 69.3 46.48 80 48.83 80"/>
      <polygon points="57.32 80 72.76 72.76 72.01 72.01 54.96 80 57.32 80"/>
      <polygon points="65.81 80 75.47 75.47 74.72 74.72 63.45 80 65.81 80"/>
      <polygon points="74.29 80 78.18 78.18 77.43 77.43 71.94 80 74.29 80"/>
    </svg>
  </div>
}

export default Release

export const query = graphql `
  query ($id: String) {
    releasesYaml(id: {eq: $id}) {
      id
      releaseNum
      text
      tracks {
        choreo {
          action
          beats
          effort
          gear
          music
          speed
          time
        }
        artist
        bpm
        duration
        profile
        title
      }
      colors {
        background
        text
        accent
        bar
        header
      }
      cover {
        childImageSharp {
          gatsbyImageData(width: 260, quality: 100)
        }
      }
    }
  }
`